@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.MuiInputLabel-root, .MuiInputLabel-root::before, .MuiInputLabel-root::after {
    border-width: 2px;
    border-style: solid;
    border-color: white;
    background: white;
}

.button_icons {
  width: "15px";
  height: "40px";
  
  cursor: pointer;
  color: #9d2d39;
}
.button_icons:hover {
    background: #9d2d39;
    color: #FFFF;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
